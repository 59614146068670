import React from "react"
import {
  ProductType,
  ProductAttributeType,
  ProductFilterType,
} from "../../types"
import Layout from "../components/Layout/Layout"
import SEO from "../components/seo"
import ChildProduct from "../components/Products/ChildProduct/ChildProduct"
import { MAGENTO_URL } from "../../userglobals"
import { graphql } from "gatsby"

type ChildProductTemplateType = {
  pageContext: {
    child: ProductType
    productAttributes: ProductAttributeType[]
    filter: ProductFilterType[]
    lastmoddate: String
  }
  location: Location
  data: any
}

const childProductTemplate: React.FC<ChildProductTemplateType> = ({
  pageContext: { child, productAttributes, filter, lastmoddate },
  location,
  data,
}) => {
  var metaTitle = "";
  var metaDescription = "";
  var metaKeyword = "";

  const { site } = data;

  const schema = {
    "@context": "https://schema.org/", 
    "@type": "Product", 
    "name": child.name,
    "image": MAGENTO_URL + "media/catalog/product" + child.image,
    "description": child.custom_attributes.find(x => x.attribute_code === 'description').value,
    "brand": "Virtual Joias",
    "sku": child.sku,
    "offers": {
      "@type": "Offer",
      "url": site.siteMetadata.siteUrl + "/" + child.slug,
      "priceCurrency": "BRL",
      "price": child.price
    }
  }



  // Exemplo de Schema com Review
  // const schema = {
  //   "@context": "https://schema.org/",
  //   "@type": "Product",
  //   name: child.name,
  //   image: MAGENTO_URL + "media/catalog/product" + child.image,
  //   description: child.custom_attributes.find(
  //     x => x.attribute_code === "description"
  //   ).value,
  //   brand: "Virtual Joias",
  //   sku: child.sku,
  //   offers: {
  //     "@type": "Offer",
  //     url: site.siteMetadata.siteUrl + "/" + child.slug,
  //     priceCurrency: "BRL",
  //     price: child.price,
  //   },
  //   "aggregateRating": {
  //     "@type": "AggregateRating",
  //     "ratingValue": "3",
  //     "ratingCount": "2",
  //     "reviewCount": "2"
  //   },
  //   "review": [{
  //     "@type": "Review",
  //     "name": "Teste Review",
  //     "reviewBody": "Excelente produto, entrega rapida, maravilhoso, incrível, ótimo, perfeito!!!!",
  //     "reviewRating": {
  //       "@type": "Rating",
  //       "ratingValue": "5"
  //     },
  //     "datePublished": "2020-09-01",
  //     "author": {"@type": "Person", "name": "John Doe"},
  //     "publisher": {"@type": "Organization", "name": "Virtual Joias"}
  //   },{
  //     "@type": "Review",
  //     "name": "Outra Review",
  //     "reviewBody": "Não gostei, foi ruim, não tá legal.",
  //     "reviewRating": {
  //       "@type": "Rating",
  //       "ratingValue": "1"
  //     },
  //     "datePublished": "2019-01-15",
  //     "author": {"@type": "Person", "name": "Jane Doe"},
  //     "publisher": {"@type": "Organization", "name": "Virtual Joias"}
  //   }]
  // }

  child.custom_attributes.forEach(x => {
    switch (x.attribute_code) {
      case "meta_title": {
        metaTitle = x.value
        break
      }
      case "meta_description": {
        metaDescription = x.value
        break
      }
      case "meta_keyword": {
        metaKeyword = x.value
        break
      }
      default: {
        break
      }
    }
  })

  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDescription}
        keywords={metaKeyword}
        schemaMarkup={schema}
      />
      <ChildProduct
        child={child}
        productAttributes={productAttributes}
        filter={filter}
        location={location}
      />
    </Layout>
  )
}

export default childProductTemplate

export const query = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`