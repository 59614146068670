import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { MICROSERVICE_CHILD, MICROSERVICE_WISHLIST } from '../../../../userglobals'
import { navigate } from 'gatsby'
import {
   ProductType,
   ProductAttributeType,
   ProductFilterType,
   media_gallery_entry
} from 'types'
import {
   addToWishlist,
   removeFromWishlist
} from '../../../store/actions'
import {
   ButtonAddToCart,
   ButtonAddToWishlist,
   ButtonShare,
   CustomOptions,
   IncludedItens,
   ProductDetails,
   ProductImagesCarousel,
   ProductName,
   ProductPrice,
   ProductReviews,
   ProductSpecifications,
   ProductSwatches,
   Sizes,
   Tips
} from '../components'

type ChildProductType = {
   child: ProductType;
   productAttributes: ProductAttributeType[];
   filter: ProductFilterType[];
   location: Location
}

var brothers: ProductType[] | null = null;

const handleCustomOptionFromLocation = (location: any, child: ProductType) => {
   //Se caso o state de location não for nulo e existir customOptions dentro dele, ele retorna o que há dentro do state de location

   if (location !== undefined && location !== null && location.state !== null && location.state !== undefined && 'customOptions' in location.state) {
      return location.state.customOptions
   }
   //Caso contrário, ele seta um state inicial customOptions com base nas custom options do produto inicial
   //Se caso o produto não tiver customOptions será simplesmente retornado um array vazio
   else {
      return []
   }
}


const ChildProduct: React.FC<ChildProductType> = ({ child, productAttributes, filter, location }) => {
   const [customOptions, setCustomOptions] = useState(handleCustomOptionFromLocation(location, child));
   const [checked, setChecked] = useState(false);
   const wishlist = useSelector((state: any) => state.wishlist);
   const userAuth = useSelector((state: any) => state.userAuth);
   console.log(child)
   //console.log(child)
   //console.log(filter)

   const handleChangeProduct = (newFilter: any) => {
      axios.post(MICROSERVICE_CHILD + 'getBrother', { fatherSKU: child.product_links[0].sku, filter: newFilter })
         .then(res => {
            //console.log(res.data.slug)
            navigate("/" + res.data.slug, { state: { customOptions } })
         })
   }
   useEffect(() => {
      const alreadyChecked = wishlist.some((x: any) => x.product_id === child.id.toString());
      const alreadyChecked2 = wishlist?.some((x: any) => x=== child.id);
      if (alreadyChecked || alreadyChecked2) {
         setChecked(true)
      }
   }, [handleChangeProduct])

   // const toggleCheckbox = () => {
   //    if (!checked) {
   //       setChecked(true)
   //       addToWishlist(child)
   //    } else {
   //       setChecked(false)
   //       removeFromWishlist(child)
   //    }
   // };
   const toggleCheckbox = () => {
      if (!checked) {
         setChecked(true)
         addToWishlist(child.id)
         if (userAuth.auth === true) {
            axios.post(MICROSERVICE_WISHLIST + "add-wishlist", { ...userAuth, productId: child.id })
            // .then(res=>console.log(res.data))
         }
      } else {
         setChecked(false)
         removeFromWishlist(child.id)
         if (userAuth.auth === true) {
            axios.post(MICROSERVICE_WISHLIST + "delete-wishlist", { ...userAuth, productId: child.id })
            // .then(res=>console.log(res.data))
         }
      }
   };

   const handleCustomOptionChange = (label: any, value: any, id: number) => {
      // //console.log(label)

      const newCustomOptions = [...customOptions];
      const indexCustom = newCustomOptions.findIndex(x => x.label === label);
      if (indexCustom === -1) {
         newCustomOptions.push({ label: label, value: value, id: id });
      } else {
         newCustomOptions[indexCustom].value = value;
      }
      setCustomOptions(newCustomOptions)
   }

   const productImagesUrls = child.media_gallery_entries.map((item: media_gallery_entry) => item.file);

   return (
      <div style={{ backgroundColor: '#fff', display: 'flex', flexWrap: 'wrap' }}>
         <div style={{ width: '44.5%', borderRight: '2px solid #f8f7f6' }}>
            <ProductImagesCarousel productImagesUrls={productImagesUrls} />
            <ProductDetails customAttributes={child.custom_attributes} />
            <IncludedItens customAttributes={child.custom_attributes} />
            <Tips customAttributes={child.custom_attributes} />
         </div>
         <div style={{ width: '55.5%' }}>
            {child.product_template === '1' ?
               <>
                  <ProductName productName={child.name} sku={child.sku} product={child} checked={checked} toggleCheckbox={toggleCheckbox} />
                  <ProductSwatches swatches={child.product_links[0].extension_attributes.configurable_product_options} filter={filter} handleChangeProduct={handleChangeProduct} />
               </> :
               child.product_template === '2' || child.product_template === '3' || child.product_template === '7' || child.product_template === '8' ?
                  <>
                     <ProductName productName={child.name} sku={child.sku} product={child} checked={checked} toggleCheckbox={toggleCheckbox} />
                     <Sizes />
                     <CustomOptions customOptions={child.product_links[0].options} template={child.product_template} handleCustomOptionChange={handleCustomOptionChange} customOptionsState={customOptions} />
                     <ProductSwatches swatches={child.product_links[0].extension_attributes.configurable_product_options} filter={filter} handleChangeProduct={handleChangeProduct} />
                  </> :
                  <>
                     <ProductName productName={child.name} sku={child.sku} product={child} checked={checked} toggleCheckbox={toggleCheckbox} />
                     <CustomOptions customOptions={child.product_links[0].options} template={child.product_template} handleCustomOptionChange={handleCustomOptionChange} customOptionsState={customOptions} />
                     <ProductSwatches swatches={child.product_links[0].extension_attributes.configurable_product_options} filter={filter} handleChangeProduct={handleChangeProduct} />
                  </>
            }
            <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between'}}>
               <ProductPrice product={child} />
               <ButtonAddToCart product={child} filter={filter} customOptionsState={customOptions} />
            </div>
            <div style={{borderTop: '2px solid #f8f7f6', display: 'flex', flexWrap: 'wrap'}}>
               <ButtonAddToWishlist checked={checked} toggleCheckbox={toggleCheckbox} />
               <ButtonShare />
            </div>
            <ProductSpecifications customAttributes={child.custom_attributes} />
         </div>
         <div style={{width: '100%', borderTop: '2px solid #f8f7f6', padding: '15px'}}>
            <ProductReviews />
         </div>
      </div>
   )
}

export default ChildProduct